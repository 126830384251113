import { render, staticRenderFns } from "./DoughnutPercentage.vue?vue&type=template&id=3e6a0c09&scoped=true"
import script from "./DoughnutPercentage.vue?vue&type=script&lang=js"
export * from "./DoughnutPercentage.vue?vue&type=script&lang=js"
import style0 from "./DoughnutPercentage.vue?vue&type=style&index=0&id=3e6a0c09&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e6a0c09",
  null
  
)

export default component.exports