<template>
    <svg class="circle-chart" viewBox="0 0 30 30" width="200" height="200"
         xmlns="http://www.w3.org/2000/svg">
        <circle class="circle-chart__background" stroke="#ddd" stroke-width="2" fill="none"
                cx="15" cy="15" r="14"/>
        <!-- stroke-dasharray is on a scale from 0 to 88 because of the viewbox parameters
        and radius of the circle : 88 ~= 2 * pi * r (with r = 14) -->
        <circle class="circle-chart__circle" :stroke="color" stroke-width="2"
                :stroke-dasharray="Math.round(proportion * 88) + ',100'"
                stroke-linecap="round" fill="none" cx="15" cy="15" r="14"/>
        <g class="circle-chart__info">
            <text class="circle-chart__percent" x="15" y="15" alignment-baseline="central"
                  text-anchor="middle" font-size="7" fill="#868a91">
                {{ Math.round(proportion * 100) }}%
            </text>
            <text class="circle-chart__percent" x="15" y="17" alignment-baseline="central"
                  text-anchor="middle" font-size="2.5" fill="#868a91">
                <tspan x="15" dy="1.2em" v-for="t in text.split(`\n`)">{{ t }}</tspan>
            </text>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'DoughnutPercentage',
    props: {
        proportion: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        },
        text: {
            type: String,
            default: ''
        }
    }
};
</script>

<style scoped>
/**
 * 1. The `reverse` animation direction plays the animation backwards
 *    which makes it start at the stroke offset 100 which means displaying
 *    no stroke at all and animating it to the value defined in the SVG
 *    via the inline `stroke-dashoffset` attribute.
 * 2. Rotate by -90 degree to make the starting point of the
 *    stroke the top of the circle.
 * 3. Using CSS transforms on SVG elements is not supported by Internet Explorer
 *    and Edge, use the transform attribute directly on the SVG element as a
 * .  workaround (https://markus.oberlehner.net/blog/pure-css-animated-svg-circle-chart/#part-4-internet-explorer-strikes-back).
 */
.circle-chart__circle {
    animation: circle-chart-fill 2s reverse; /* 1 */
    transform: rotate(-90deg); /* 2, 3 */
    transform-origin: center; /* 4 */
}

.circle-chart__info {
    animation: circle-chart-appear 2s forwards;
    opacity: 0;
    transform: translateY(0.3em);
}

svg {
    filter: drop-shadow(0 0 0.4rem rgba(0, 0, 0, 0.2));
}

@keyframes circle-chart-fill {
    to {
        stroke-dasharray: 0, 100;
    }
}

@keyframes circle-chart-appear {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
